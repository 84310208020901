import store from '@/state/store.js';
import $ from 'jquery';
export default {
  props: {
    payload: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      success: '',
      error: '',
      loading: false
    };
  },
  methods: {
    hideModal() {
      this.error = '';
      this.success = '';
      this.loading = false;
      this.$emit('close');
      $('#actionModal').modal('hide');
    },
    activeUser() {
      let payloadData = {
        data: {
          user_id: this.payload.data.id
        },
        actionData: {
          from: this.payload.from,
          index: this.payload.index
        }
      };
      return store.dispatch('admin/users/activateUserAccounts', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    deactiveUser() {
      let payloadData = {
        user_id: this.payload.data.id,
        from: this.payload.from,
        index: this.payload.index
      };
      return store.dispatch('admin/users/deactiveUser', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response2;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    tagAccount() {
      let payloadData = {
        user_id: this.payload.data.id,
        from: this.payload.from,
        index: this.payload.index
      };
      return store.dispatch('admin/users/tagAccount', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response3;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    resestPaswword() {
      let payloadData = {
        phone: this.payload.data.phone
      };
      return store.dispatch('admin/users/resetPassword', payloadData).then(message => {
        this.loading = false;
        this.success = message;
        this.$emit('test');
      }).catch(err => {
        var _err$response4;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    toggleSenderID() {
      let payloadData = {
        data: {
          status: this.payload.status,
          id: this.payload.data.id
        },
        stateData: {
          index: this.payload.index,
          senderType: this.payload.senderType
        }
      };
      return store.dispatch('senderid/toggleSenderID', payloadData).then(message => {
        this.loading = false;
        this.success = message.message;
        this.$emit('needsToRefresh');
      }).catch(err => {
        var _err$response5;
        this.loading = false;
        this.$emit('needsToRefresh');
        if ((err === null || err === void 0 || (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    toggleResellerSenderID() {
      let payloadData = {
        data: {
          status: this.payload.status,
          id: this.payload.data.id
        },
        stateData: {
          index: this.payload.index,
          senderType: this.payload.senderType
        }
      };
      return store.dispatch('senderid/toggleResellerSenderID', payloadData).then(message => {
        this.loading = false;
        this.success = message.message;
        this.$emit('needsToRefresh');
      }).catch(err => {
        var _err$response6;
        this.loading = false;
        this.$emit('needsToRefresh');
        if ((err === null || err === void 0 || (_err$response6 = err.response) === null || _err$response6 === void 0 ? void 0 : _err$response6.data) == undefined) {
          this.errors = "Network error try again.";
        }
      });
    },
    deleteNofication() {
      let payloadData = {
        notification_id: this.payload.data.id,
        index: this.payload.index
      };
      this.loading = true;
      return store.dispatch('admin/notifications/deleteNotification', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response7;
        if ((err === null || err === void 0 || (_err$response7 = err.response) === null || _err$response7 === void 0 ? void 0 : _err$response7.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loading = false);
    },
    confirmAccount() {
      let payloadData = {
        id: this.payload.data.id,
        index: this.payload.$index
      };
      this.loading = true;
      return store.dispatch('admin/reseller/confirmReseller', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response8;
        if ((err === null || err === void 0 || (_err$response8 = err.response) === null || _err$response8 === void 0 ? void 0 : _err$response8.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loading = false);
    },
    togglePromoCode() {
      let payloadData = {
        id: this.payload.data.row.id,
        index: this.payload.data.$index,
        status: this.payload.status
      };
      return store.dispatch('admin/promocodes/togglePromoCode', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response9;
        if ((err === null || err === void 0 || (_err$response9 = err.response) === null || _err$response9 === void 0 ? void 0 : _err$response9.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loading = false);
    },
    deleteIndustry() {
      let payloadData = {
        industry_id: this.payload.data.row.id,
        key: this.payload.data.$index
      };
      return store.dispatch('admin/industries/deleteIndustry', payloadData).then(message => {
        this.loading = false;
        this.success = message;
      }).catch(err => {
        var _err$response10;
        if ((err === null || err === void 0 || (_err$response10 = err.response) === null || _err$response10 === void 0 ? void 0 : _err$response10.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loading = false);
    },
    unpublishAction() {
      let payloadData = {
        notification_id: this.payload.data.id,
        index: this.payload.index
      };
      return store.dispatch('admin/notifications/unpublishNotification', payloadData).then(message => {
        this.loading = false;
        this.success = message.data.message;
      }).catch(err => {
        var _err$response11;
        if ((err === null || err === void 0 || (_err$response11 = err.response) === null || _err$response11 === void 0 ? void 0 : _err$response11.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loading = false);
    },
    approveAction() {
      let payloadData = {
        data: {
          status: this.payload.status,
          id: this.payload.data.id
        },
        stateData: {
          index: this.payload.index,
          senderType: this.payload.senderType
        }
      };
      return store.dispatch('senderid/getSenderIDs', payloadData).then(() => {
        this.$message({
          type: 'success',
          message: ' Approved'
        });
        this.loading = false;
        this.success = message;
      }).catch(() => {
        this.this.$message({
          type: 'error',
          message: 'There was an error'
        });
      });
    },
    submitAction() {
      this.loading = true;
      if (this.payload.action == 'resetPassword') {
        return this.resestPaswword();
      }
      if (this.payload.action == 'tagAccount') {
        return this.tagAccount();
      }
      if (this.payload.action == 'deactiveUser') {
        return this.deactiveUser();
      }
      if (this.payload.action == 'senderID') {
        return this.toggleSenderID();
      }
      if (this.payload.action == 'resSenderID') {
        return this.toggleResellerSenderID();
      }
      if (this.payload.action == 'deleteNotification') {
        return this.deleteNofication();
      }
      if (this.payload.action == 'confirmRequest') {
        return this.confirmAccount();
      }
      if (this.payload.action == 'togglePromoCode') {
        return this.togglePromoCode();
      }
      if (this.payload.action == 'activate') {
        return this.activeUser();
      }
      if (this.payload.action == 'deleteIndustry') {
        return this.deleteIndustry();
      }
      if (this.payload.action == 'unpublishNotification') {
        return this.unpublishAction();
      }
    }
  },
  mounted() {
    this.error = '';
    this.success = '';
    this.loading = false;
  }
};