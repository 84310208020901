import "core-js/modules/es.array.push.js";
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import routes from "./routes";
import Storage from "../services/storage";
import { Notification } from "element-ui";
const storage = new Storage();
Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page"
});
const router = new VueRouter({
  routes: routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

//admin router guide
router.beforeEach((to, from, next) => {
  let admin = storage.getSavedState("auth.admin");
  let user = storage.getSavedState("currentUser");
  if (admin && admin.token && user && user.token) {
    return next();
  }
  if (user && user.token) {
    if (to.path.includes("/admin/") && to.path !== "/signin") {
      return next("/signin");
    }
  } else if (admin && admin.token) {
    if (!to.path.includes("/admin/") && to.path !== "/signin") {
      return next("/signin");
    }
  }
  next();
});
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/signin", "/signup", "/forget-password", "/receive_auth_code", "refer_a_friend", "/refer", "/verify-account"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggedInUser = storage.getSavedState("currentUser");
  const loggedInAdmin = storage.getSavedState("auth.admin");
  const isFromOmniChannel = storage.getSavedState("source");
  if (process.env.NODE_ENV === "development") {
    console.log("isFromOmniChannel", isFromOmniChannel);
  }
  if (publicPages.includes(routeTo.path)) {
    if (loggedInAdmin && loggedInAdmin.token && isFromOmniChannel === "omniChannel") {
      return next("/admin/users/all");
    }
    if (loggedInUser && loggedInUser.token && isFromOmniChannel === "omniChannel") {
      return next("/overview");
    }
  }
  if (authpage) {
    if (loggedInAdmin && loggedInAdmin.token || loggedInUser && loggedInUser.token) {
      // ! check if trying to navigate to Voice page while user countrry is CI or SN
      if (routeTo.path === "/voice/messages" && loggedInUser.country && (loggedInUser.country === "CI" || loggedInUser.country === "SN")) {
        return next("/sms/messages");
      }
      next();
    } else {
      return next({
        path: "/signin",
        query: {
          returnUrl: routeTo.fullPath
        }
      });
    }
  }
  next();
});

// router on error for 401
router.onError(error => {
  // get 401 error
  if (error.response.status === 401) {
    // clear all local storage
    storage.clearAll();
    // redirect to login page
    Notification.error({
      title: "Error",
      message: "You must be logged in to access this page"
    });
    router.push("/signin");
  }
});
export default router;