import "core-js/modules/es.array.push.js";
import axios from "axios";
import store from "../state/store";
import router from "../router";
import { Notification } from "element-ui";
import Storage from "@/services/storage";
const storage = new Storage();

// add interceptor

// Create an interceptor as a vue plugin
const HttpInterceptor = {
  install(Vue) {
    let showNotification = false;
    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        showNotification = true;
        store.dispatch("auth/logout").then(() => {
          //   clear the storage
          storage.clearSavedData("token");
          storage.clearSavedData("currentUser");
          storage.clearSavedData("auth.admin");
          router.push({
            name: "signin"
          }).then(() => {
            if (showNotification == true) {
              Notification.error({
                title: "Error",
                message: "You must be logged in to access this page"
              });
              showNotification = false;
            }
          });
          // router.go();
        });
      }
      return Promise.reject(error);
    });
  }
};
export default HttpInterceptor;